.retailin-card {
  box-shadow: rgb(197 200 202) 0px 0px 1px 1px;
  border-radius: 8px;
}

.retailin-card:hover {
  box-shadow: rgb(197 200 202) 0px 0px 3px 3px;
}

.retail-warning1 {
  color: orange;
}

.retail-warning2 {
  color: red;
}

.retail-warning {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.retail-warnings-container {
  position: absolute;
  width: 20%; 
  top: 10px;
  margin-inline-start: -250px;
}

.warning-bg-non-active {
  color: var(--bs-table-striped-color);
}

.warning-bg-active {
  color: var(--bs-yellow);
}

.graph-modal {
  transition: transform 0.3s ease-out;
}

.td-padding {
  padding: 0.25rem 0.25rem !important;
}

.card-body {
  position: relative;
}

.popover .popover-header {
  font-size: 1rem;
  font-weight: 500;
  background-color: #fff8dd;
  border-bottom: 1px solid #f5f8fa;
}

.popover .popover-dismiss {
  position: absolute;
  top: 0.85rem;
  left: 0.85rem;
  height: 1.5rem;
  width: 1.5rem;
}

.popover .popover-dismiss i {
  color: #a1a5b7;
}

.popover .popover-dismiss:hover i {
  color: #009ef7;
}

.popover .popover-dismiss+.popover-header {
  padding-left: 2.75rem;
}

.tableFixHead {
  overflow: auto;
  height: calc(100vh - 15vw)
}

.LoadtableFixHead {
  overflow: auto;
  height: calc(100vh - 57vh)
}

.table>thead {
  position: sticky;
  top: 0;
}

.aside:hover .language-picker {
  visibility: initial;
}

.drawer-on .language-picker {
  visibility: initial;
}

.language-picker {
  visibility: hidden;
}

.header {
  background-color: transparent !important;
}

[dir="rtl"] .drawer.drawer-end {
  right: unset;
}

.user-option:hover {
  cursor: pointer;
  background-color: var(--bs-light-primary);
}

input[type="checkbox"]:not(:checked) {
  border: solid 1px gray !important;
}

[dir=rtl] .chart-fix-rtl svg text[text-anchor=middle] {
  direction: rtl;
}
